//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WelcomePage',
  props: {
    dataObj: {
      type: Object,
      default: Object
    }
  },
  data () {
    return {
      loading: false,
      remark: '',
      languageInContentBtn: [],
      BackgroundColor: '',
      BackgroundContent: {
        images: [
          {
            size: 'default',
            url: ''
          }
        ]
      },
      RATIO_X: 4,
      RATIO_Y: 3,
      textleft: '',
      textright: '',
      styleObjectBtn: {
        color: '',
        backgroundColor: '',
        backgroundColorHover: ''
      }
    }
  },
  computed: {
    content () {
      return this.dataObj
    },
    advertisement () {
      if (this.content && this.content.welcomePageType === 'Advertisement') {
        return true
      }
      return false
    },
    adsCode () {
      if (this.content.device === 'Desktop') {
        return this.content.adsCodeDesktop
      } else if (this.content.adsCodeMobile) {
        return this.content.adsCodeMobile
      } else {
        // window.location = this.localePath({ name: 'home' })
        return null
      }
    }
  },
  async mounted () {
    this.loading = false
    await this.render()
    this.loading = true
    // set time to redirec to home
    // close overlay loading
    this.$nextTick(() => {
      if (this.content != null) {
        // ads link
        this.addLinkAds()
      }
      this.$nuxt.$loading.finish()
      this.setRedirectInSec()
    })
    window.addEventListener('resize', this.render)
  },
  beforeDestroy () {
    document.querySelector('body').style.backgroundImage = ''
  },
  methods: {
    setRedirectInSec () {
      if (this.content.redirectInSec) {
        setTimeout(() => {
          window.location = this.localePath({ name: 'home' })
        }, (this.content.redirectInSec * 1000))
      }
    },
    render () {
      if (this.advertisement === true && !this.adsCode) {
        window.location = this.localePath({ name: 'home' })
      }
      if (this.content != null) {
        this.languageInContentBtn = this.content.languageInContent
        this.styleObjectBtn.color = this.content.btnFontColor
        this.styleObjectBtn.backgroundColor = this.content.btnColor
        this.styleObjectBtn.backgroundColorHover = this.content.btnColor
        this.remark = this.content.welcRemark
        if (this.content.device === 'Desktop') {
          this.renderDevice(this.content.bgImage)
          this.BackgroundContent = this.content.cntImage
        } else if (this.content.device === 'Mobile' && (this.content.mobileBgImage !== null || this.content.mobileCntImage !== null)) {
          this.renderDevice(this.content.mobileBgImage)
          this.BackgroundContent = this.content.mobileCntImage
        } else if (this.content.device === 'Mobile' && this.content.mobileBgImage === null && this.content.mobileCntImage === null) {
          this.renderDevice(this.content.bgImage)
          this.BackgroundContent = this.content.cntImage
        }
      }
    },
    renderDevice (arrayImage) {
      const sizes = [
        {
          size: 576,
          name: 'sm'
        },
        {
          size: 768,
          name: 'md'
        },
        {
          size: 992,
          name: 'lg'
        },
        {
          size: 1200,
          name: 'xl'
        },
        {
          size: 1400,
          name: 'default'
        }
      ]
      const sizeOnLoad = []
      if (arrayImage != null) {
        arrayImage.images.forEach(function (item) {
          const size = sizes.find(e => e.name === item.size)
          if (size !== undefined) {
            sizeOnLoad.push(size)
          }
        })
        const WindowWidth = window.innerWidth
        const sizeWidth = sizeOnLoad.filter(e => e.size <= WindowWidth)
        const sizeName = sizeWidth.length === 0 ? sizeOnLoad[0].name : sizeWidth.reverse()[0].name
        document.querySelector('body').style.backgroundImage = `url(${arrayImage.images.find(e => e.size === sizeName).url})`
      } else {
        this.BackgroundColor = this.content.bgColor
      }
    },
    handleClickTracking (title) {
      // const { welcomePageName } = this.$store.state.contents.content
      const dataAction = 'Welcome Page - Button' // Data Action(Section)
      // if (welcomePageName) {
      //   dataAction = `Welcome Page ${welcomePageName}`
      // }
      this.clickTracking({
        title,
        sectionName: dataAction
      })
    },
    addLinkAds () {
      if (this.content.imageClickLink && !this.advertisement) {
        const createA = document.createElement('a')
        createA.setAttribute('href', this.content.imageClickLink || '#')
        createA.setAttribute('class', 'stretched-link')
        createA.onclick = () => {
          this.clickTracking({
            title: this.content.welcomePageName,
            sectionName: 'Welcome Page - Banner'
          })
        }
        this.$refs.cntImage.appendChild(createA)
      }
    }
  }
}
